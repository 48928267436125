import React from 'react'
import Home from './Home'
import HomeScreen from './HomeScreen'
import SignInScreen from './SignInScreen'
import SignUpScreen from './SignUpScreen'
import { NavigationContainer } from '@react-navigation/native'
import { createStackNavigator } from '@react-navigation/stack'
import { withFirebaseHOC } from './globals/Firebase'

import { View, Text, Image, Animated } from 'react-native'
import { Button, Colors } from './globals/components/Buttons'

function LogoTitle() {
	return (
		<Image
			style={{ width: 50, height: 50 }}
			source={ require('./assets/icon.png') }
		/>
	)
}

function ForecastingScreen({ navigation }) {
	return (
		<View style={{ flex: 1, alignItems: 'center', justifyContent: 'center', margin: 20 }}>
			<Text>Sorry, you do not have access to this function. Please contact us at support@virevol.com </Text>
			<Button title="Go back" onPress={() => navigation.goBack()} />
		</View>
	)
}

function SplashScreen() {
	return (
		<View style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}>
			<Image
				style={{ width: 100, height: 100 }}
				source={ require('./assets/icon.png') }
			/>
			<Text style={{ fontSize : 20 }}> Virevol Metis </Text>
		</View>
	)
}

const Stack = createStackNavigator()
// const DURATION = 1000
const DURATION = 20

class AppContainer extends React.Component {

	state = {
		isLoading: true,
		fadeValue: new Animated.Value(0)
	}

	_start = () => {
		Animated.timing(this.state.fadeValue, {
			toValue: 1,
			duration: DURATION,
			useNativeDriver: true,
		}).start()
	}

	performTimeConsumingTask = async() => {
		//	MEH: Hard coded value! should be in .env, or ideally, DB
		return new Promise((resolve) =>
			setTimeout(
				() => { resolve('result') },
				DURATION
			)
		)
	}

	componentDidMount = async () => {

		this._start()
		await this.performTimeConsumingTask()
		
		this.props.firebase.shared.checkUserAuth(
			() => {
				console.log('User is logged in')
				this.setState({ isLoggedIn: true , isLoading: false })
			},
			() => {
				console.log('User is logged out')
				this.setState({ isLoggedIn: false, isLoading: false })
			}
		)
	}

	render() {

		if (this.state.isLoading) {
			// We haven't finished checking for the token yet
			return <SplashScreen />
		}

		return (
			<NavigationContainer>
				<Stack.Navigator
					screenOptions={{
						headerStyle: {
							backgroundColor: Colors.Primary3, // '#f4511e',
						},
						headerTintColor: '#fff',
						headerTitleStyle: {
							fontWeight: 'bold',
						},
						// headerTitle: props => <LogoTitle {...props} />,
					}}
					initialRouteName="Metis"
				>
					{ this.state.isLoggedIn ? (
						<>
							<Stack.Screen name="Metis" component={HomeScreen} />
							<Stack.Screen name="Image Intelligence" component={Home} />
							<Stack.Screen name="Demand Forecasting" component={ForecastingScreen} />
							<Stack.Screen name="Price Fungibility" component={ForecastingScreen} />
						</>
					) : (
						<>
							<Stack.Screen name="SignIn" title="Sign in" component={SignInScreen} />
							{ true && (<Stack.Screen name="SignUp" title="Sign up" component={SignUpScreen} /> )}
						</>
					) }
				</Stack.Navigator>
			</NavigationContainer>
		)
	}
}


export default withFirebaseHOC(AppContainer)
