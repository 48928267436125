import React from 'react'
import { TouchableOpacity, Text, Image, StyleSheet, View } from 'react-native'

const Button = ({onPress, title, ...props}) => {
	return (
		<TouchableOpacity
			onPress={onPress}
			style={!props.clear ? styles.button: styles.buttonClear}
		>
			<Text
				style={!props.clear ? styles.buttonText: styles.buttonTextClear}
			>
				{ title }
			</Text>
		</TouchableOpacity>
	)
}

const TextInput = (props) => {
	return (
		<TextInput
			{...props}
			style={styles.input}
		/>
	)
}

// Sample data for table
const data = {
	'header': ['col1', 'col2', 'col3'],
	'rows': [
		[10, 20, 30],
		[1, 2, 3],
	]
}

const Table = (data) => {
	const { header, rows } = data.data

	const renderRowValues = (r, k) => (
		<View
			key={k}
			style={[ styles.tableRow, k === -1 ? styles.tableHeaderRow : {} ]}
		>
			{ r.map((h, key) => (
				<Text
					key={key}
					style={[ styles.tableText, k === -1 ? styles.tableHeaderText : {} ]}
				>
					{h}
				</Text>
			))}
		</View>
	)

	return (
		<View style={styles.tableContainer} >
			{ renderRowValues(header, -1) }
			{ rows.map((r, k) => {
					return renderRowValues(r, k)
				})
			}
		</View>
	)
}

const Colors = {
	Primary1: '#FEC4AC', // peach
	Primary2: '#012842', // navy
	Primary3: '#D6580E', // orange
	Primary4: '#CE283D', // red
	Primary5: '#FFFFFF', // white

	Secondary1: '#999999', // dark grey
	Secondary2: '#D2D2D2', // silver grey
	Secondary3: '#F8F8F8', // off white
}

export { Button, TextInput, Colors, Table }

const styles = StyleSheet.create({
	button: {
		backgroundColor: Colors.Primary1,
		borderRadius: 7,
		padding: 20,
		margin: 10,
	},
	buttonClear: {
		overflow: 'visible',
		alignItems: 'center',
		margin: 10,
	},
	buttonText: {
		fontSize: 20,
		color: Colors.Primary2 // '#fff',
	},
	buttonTextClear: {
		fontSize: 20,
		color: Colors.Primary2, // '#888',
	},
	input: {
		color: Colors.Primary2, // '#888',
		fontSize: 18,
		margin: 10,
		marginHorizontal: 15,
		marginBottom: 10,
	},
	instructions: {
		color: Colors.Primary2, // '#888',
		fontSize: 18,
		marginHorizontal: 15,
		marginBottom: 10,
	},
	tableContainer: {
		flex: 1,
		backgroundColor: Colors.Secondary3,
		width: '100%',
		borderRadius: 7,
		borderColor: 'grey',
		margin: 20,
	},
	tableRow: {
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'space-between',
		padding: 10,
	},
	tableHeaderRow: {
		backgroundColor: Colors.Secondary2,
		borderRadius: 7,
	},
	tableText: {
		flex: 1,
		color: Colors.Primary2, // '#888',
		fontSize: 18,
	},
	tableHeaderText: {
		color: Colors.Primary4, // '#888',
		fontWeight: 'bold',
		fontSize: 22,
	},
})
