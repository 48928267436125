import React from 'react'
import { View, Text, StyleSheet } from 'react-native'
import { Button, } from './globals/components/Buttons'
import { withFirebaseHOC } from './globals/Firebase'

const data = {
	'header': ['col1', 'col2', 'col3'],
	'rows': [
		[10, 20, 30],
		[1, 2, 3],
	]
}

function HomeScreen({ navigation, ...props }) {
	const { signOut } = props.firebase.shared

	return (
		<View style={{ flex: 1, alignItems: 'flex-start', justifyContent: 'center', margin: 20 }}>

			<Button
				title="Search by image"
				onPress={() => navigation.navigate('Image Intelligence')}
			/>
			<Button
				title="Forecast demand"
				onPress={() => navigation.navigate('Demand Forecasting')}
			/>
			<Button
				title="Forecast price"
				onPress={() => navigation.navigate('Price Fungibility')}
			/>

			<View style={{ marginTop: 20, flex: 1 }} >
				<Button
					clear={true}
					title="Logout"
					onPress={signOut}
				/>
			</View>
			
		</View>
	)
}

export default withFirebaseHOC(HomeScreen)
