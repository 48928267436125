import React from 'react'
import { View, TextInput, Text } from 'react-native'
import { Button } from './globals/components/Buttons'
import { withFirebaseHOC } from './globals/Firebase'

const PrettierTextInput = (props) => {
  return (
    <TextInput
			style={{
				color: '#222',
				fontSize: 18,
				margin: 10,
				marginHorizontal: 15,
			}}
			placeholderTextColor={{
				color: '#888',
			}}
      {...props}
    />
  )
}


function SignInScreen({ navigation, ...props }) {
	const [email, setEmail] = React.useState('')
	const [password, setPassword] = React.useState('')
	const [error, setError] = React.useState('')

	const signIn = () => {
		const { loginWithEmail } = props.firebase.shared
		loginWithEmail(email, password)
		.then(() =>
			setError('')
		)
		.catch((e) =>
			setError(e.message)
		)
	}

	const reset = () => {
		try {
			const { resetPassword } = props.firebase.shared
			resetPassword(email)
			.then(() =>
				setError('Email sent')
			)
			.catch((e) =>
				setError(e.message)
			)
		} catch(e) {
			setError(e.message)
		}
	}

	return (
		<View>

			<Text style={{ color: 'red', margin: 20 }}> { error } </Text>

			<PrettierTextInput
				placeholder="Email"
				value={email}
				onChangeText={setEmail}
			/>
			<PrettierTextInput
				placeholder="Password"
				value={password}
				onChangeText={setPassword}
				secureTextEntry
			/>

			<Button title="Sign in" onPress={signIn} />

			{ false && (
				<Button clear={true} title='sign up instead' onPress={() => navigation.navigate('SignUp') } />
			)}

			<Button
				clear={true}
				title='reset'
				onPress={reset}
			/>

		</View>
	)
}

export default withFirebaseHOC(SignInScreen)
