import React from 'react'
import Firebase, { FirebaseProvider } from './globals/Firebase'
import AppContainer from './AppContainer'

export default function App () {
	return (
		<FirebaseProvider value={Firebase}>
			<AppContainer />
		</FirebaseProvider>
	)
}
