import React from 'react'
import { View, TextInput, TouchableOpacity, Text } from 'react-native'
import { Button } from './globals/components/Buttons'
import { withFirebaseHOC } from './globals/Firebase'

const PrettierTextInput = (props) => {
  return (
    <TextInput
			style={{
				color: '#222',
				fontSize: 18,
				margin: 10,
				marginHorizontal: 15,
			}}
			placeholderTextColor={{
				color: '#888',
			}}
      {...props}
    />
  )
}

function SignUpScreen({ navigation, ...props }) {
	const [email, setEmail] = React.useState('')
	const [password, setPassword] = React.useState('')
	const [password2, setPassword2] = React.useState('')
	const [error, setError] = React.useState('')

	const signUp = () => {
		const { signupWithEmail } = props.firebase.shared
		if (password !== password2) {
			setError('The passwords do not match')
		} else {
			email &&
				signupWithEmail(email, password)
					.then(() =>
						setError('')
					)
					.catch((e) =>
						setError(e.message)
					)
		}
	}

	return (
		<View>

			<Text style={{ color: 'red', margin: 20 }}> { error } </Text>

			<PrettierTextInput
				placeholder="Email"
				value={email}
				onChangeText={setEmail}
			/>
			<PrettierTextInput
				placeholder="Password"
				value={password}
				onChangeText={setPassword}
				secureTextEntry
			/>
			<PrettierTextInput
				placeholder="Confirm password"
				value={password2}
				onChangeText={setPassword2}
				secureTextEntry
			/>

			<Button title="Sign Up" onPress={signUp} />

			<Button
				clear={true}
				title='sign in instead'
				onPress={() => navigation.navigate('SignIn') }
			/>

		</View>
	)
}

export default withFirebaseHOC(SignUpScreen)
