// Firebase App (the core Firebase SDK) is always required and
// must be listed before other Firebase SDKs
var firebase = require("firebase/app")

// Add the Firebase products that you want to use
require("firebase/auth")

// import { API_KEY, AUTH_DOMAIN, DATABASE_URL, PROJECT_ID, STORAGE_BUCKET, MESSAGING_SENDER_ID, } from 'react-native-dotenv'

const currentSchemaVersion = 'v1'

class Firebase {
	constructor() {
		const firebaseConfig = {
			apiKey: "AIzaSyDwy5blV2DoaZyYxUNrXM6NzOTBQt1eYz8",
			authDomain: "manufacturer-20043.firebaseapp.com",
			databaseURL: "https://manufacturer-20043.firebaseio.com",
			projectId: "manufacturer-20043",
			storageBucket: "manufacturer-20043.appspot.com",
			messagingSenderId: "917669786605",
			appId: "1:917669786605:web:d4b68fb6cce88c4f7cede4",
			measurementId: "G-GZBL5V08BE"
		}

		firebase.initializeApp(firebaseConfig)
		
//		firebase.initializeApp({
//			apiKey: API_KEY,
//			authDomain: AUTH_DOMAIN,
//			databaseURL: DATABASE_URL,
//			projectId: PROJECT_ID,
//			storageBucket: STORAGE_BUCKET,
//			messagingSenderId: MESSAGING_SENDER_ID,
//		})
	}

	get currentUser() {
		return firebase.auth().currentUser
	}

	loginWithEmail = (email, password) => 
		firebase.auth().signInWithEmailAndPassword(email, password)

	signupWithEmail = (email, password) => 
		firebase.auth().createUserWithEmailAndPassword(email, password)
			.then(() => {
				var user = firebase.auth().currentUser

				user.sendEmailVerification()
				.then(function() {
					console.log('Verification email sent')
				}).catch(function(error) {
					console.error(error.message)
				})
			})

	signOut = () =>
		firebase.auth().signOut()

	resetPassword = (emailAddress) =>
		firebase.auth().sendPasswordResetEmail(emailAddress)

	checkUserAuth = async (signedInFn, anonFn) =>
		firebase.auth().onAuthStateChanged(user => {
			if (user) {
				signedInFn()
			} else {
				anonFn()
			}
			return user
		})

}

Firebase.shared = new Firebase()
export default Firebase
