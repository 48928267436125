import React, { useState, useEffect } from 'react'
import { Image, StyleSheet, Text, View, Platform, ScrollView, ActivityIndicator, TouchableOpacity } from 'react-native'
import { Button, Table } from './globals/components/Buttons'
import { withFirebaseHOC } from './globals/Firebase'
import * as ImagePicker from 'expo-image-picker'

// TODO
// Show price histograms
// Let them choose which ones to use
// yarn add styled-components
// On mobile, show long, instead of wide
// Progressive load for images

function Home(props) {

	let [selectedImage, setSelectedImage] = useState(null)
	let [predictions, setPredictions] = useState(null)
	let [error, setError] = useState(null)
	let [overviewStats, setOverviewStats] = useState(null)
	let [brandStats, setBrandStats] = useState(null)
	let [selects, setSelects] = useState([])

	let openImagePickerAsync = async () => {
		setError(null)
		// console.log('openImagePickerAsync: started')
		let permissionResult = await ImagePicker.requestCameraRollPermissionsAsync()

		if (permissionResult.granted === false) {
			alert('Permission to access camera roll is required!')
			return
		}

		// TODO:
		// IOS/Android permissions
		// ImagePicker.launchCameraAsync
		let pickerResult = await ImagePicker.launchImageLibraryAsync({
				mediaTypes: ImagePicker.MediaTypeOptions.All,
				allowsEditing: true, // On Android the user can crop and rotate the image and on iOS simply crop it.
				quality: 0.3,
				base64: false, // This does not work in the current expo version
			})
		if (pickerResult.cancelled === true) {
			return
		}

		setSelectedImage({ localUri: pickerResult.uri })
		similar(pickerResult.uri, (preds) => {
			setPredictions(preds)
			setSelects([])
			// calculateStats(preds)
		})
	}

	// TODO: Written, not tested or used... try on different platforms
	let base64_to_blob = async (base64) => {
		if (Platform.OS === 'web') {
			const blob = await fetch(base64)
			const formData = new FormData()
			const file = await new File([blob], "file.png")
			return file
		} else {
			return base64
		}
	}

	let similar = (uri, fn) => {

		const uriParts = uri.split('.')
		const fileType = uriParts[uriParts.length - 1]; // Print this for a surprise
		// console.log(`Looking for similar items...`)

		// Expo is very rough around the edges. I don't know why you waste your time with this.. idiot
		// ImagePicker is returning a base64 object on web, not a file.. so... hrmph...
		// https://gist.github.com/AshikNesin/ca4ad1ff1d24c26cb228a3fb5c72e0d5
		// Opened issue https://github.com/expo/expo/issues/9984

		fetch(uri)
			.then(res => res.blob())
			.then(blob => {
				const formData = new FormData()
				const file = new File([blob], "file.png")
				formData.append('file', file)

				fetch('https://sartorial.api.virevol.com/v1/file2items', {
					method: 'POST',
					mode: 'cors',
					headers: {
						Accept: 'application/json',
						// 'Content-Type': 'multipart/form-data',
						// 'Content-Type': 'application/json',
						'X-Api-Key': 'icecream',
						'num_matches': 30,
					},
					body: formData,
				})
				.then(response => response.json())
				.then(function(responseJson) {
					const retArr = responseJson.prediction
					fn(retArr)
				})
				.catch((error) => {
					// console.error(error)
					setError('Could not get similar images. Please contact support@virevol.com')
				})
		})
		.catch(error => {
			// console.error(error)
			setError('Could not get similar images. Please contact support@virevol.com')
		})
	}

	const round2 = (d) =>
		parseInt(Math.round(100 * d) / 100)

	const groupCount = (array, key) =>
		array.reduce((accum, currentItem) => {
			const group = currentItem[key];
			if (!accum[group]) accum[group] = 0;
			accum[group] += 1;
			return accum;
		}, {})

	useEffect(() => {
		// const items = predictions || []
		const selectsSet = new Set(selects)
		const items = predictions && predictions
			.filter((i, k) => !selectsSet.has(k))
			|| []
		// console.log('Items are ', items)
		const sum = items.reduce((accum, curr) => accum + curr.price, 0)
		const mean = sum/items.length
		const stdev = Math.sqrt(
			items.reduce((accum, curr) =>
				accum + Math.pow(mean - curr.price, 2)
			, 0)
		) / items.length

		setOverviewStats({
			'header': ['Items', 'Mean', 'Standard Deviation'],
			'rows'  : [
				[items.length, parseInt(sum), stdev ? parseInt(stdev) : 'NA']
			]
		})

		const brands = groupCount(items, 'brand')
		setBrandStats({
			'header': ['Brand', 'Count'],
			'rows'  : Object.entries(brands)
		})

		// console.log(groupCount(items, 'retailer'))
	}, [selects])

	const renderItem = (i, k) => {
		const selectsSet = new Set(selects)
		return (
			<TouchableOpacity
				key={k}
				style={[ styles.thumbcontainer, selectsSet.has(k) && { opacity: 0.2, backgroundColor: 'grey'} ]}
				onPress={() => {
					let newSelects = selects.slice()
					if (!newSelects.includes(k)) {
						newSelects.push(k)
					} else {
						newSelects = newSelects.filter((e) => e !== k)
					}
					// console.log('new selects are => ', newSelects)
					setSelects(newSelects)
				}}
			>
				<Image source={{ uri: i.images[i.hero_img_idx] }} style={styles.thumbnail} />
				<Text style={styles.instructions}> { i.brand } </Text>
				<Text style={styles.instructions}> { i.currency }{ i.price } </Text>
			</TouchableOpacity>
		)
	}

	return (
		<View style={styles.container}>

			<Image source={ require('./assets/icon.png') } style={styles.logo} />

			<Text style={styles.instructions}>
				To price a garment (US only, see below), upload a photo please
			</Text>

			<Button
				onPress={openImagePickerAsync}
				title='Pick a photo'
			/>

			{ selectedImage && (
				<View style={styles.thumbcontainer}>

					<Image source={{ uri: selectedImage.localUri }} style={styles.thumbnail} />

					<View style={{margin: 10}} />

				</View>
			)}

			<Text style={styles.error}> {error} </Text>

			{ selectedImage && !predictions && (
				<View style={{ marginTop: 20 }} >
					<ActivityIndicator size='large' />
				</View>
			)}

			{ predictions && (
				<>
					<Text style={[ styles.instructions, { fontWeight: 'bold' } ]}> Similar items </Text>
					<Text style={styles.instructions}> Scroll right to view </Text>
					<ScrollView contentContainerStyle={styles.scrollviewcontainer} style={styles.scrollview} horizontal={true}>
						{ predictions.map((i, k) => renderItem(i, k)) }
					</ScrollView>
				</>
			)}

			<View style={{ flex: 1, margin: 20 }} >

				{ predictions && (
					<Text style={styles.instructions}> Exclude items by selecting them above </Text>
				)}

				{ predictions && overviewStats && (
					<Table data={overviewStats} />
				)}

				{ predictions && brandStats && (
					<Table data={brandStats} />
				)}

				{ predictions && (
					<Text style={styles.instructions}>For other countries, retailers, or details, please get in touch</Text>
				)}
	
			</View>


			<View style={{ margin: 40 }} />

		</View>
	)
}

const styles = StyleSheet.create({
	container: {
		backgroundColor: '#fff',
		alignItems: 'center',
		justifyContent: 'flex-start',
		marginTop: 30,
	},
	logo: {
		width: 305,
		height: 159,
		marginBottom: 20,
	},
	instructions: {
		color: '#888',
		fontSize: 18,
		marginHorizontal: 15,
		marginBottom: 10,
	},
	button: {
		backgroundColor: 'blue',
		padding: 20,
		borderRadius: 7,
	},
	buttonText: {
		fontSize: 20,
		color: '#fff',
	},
	thumbcontainer: {
		width: 300,
		height: 300 + 100, // For the text
		borderRadius: 7,
		overflow: 'hidden',
		margin: 10,
		alignItems: 'center',
	},
	thumbnail: {
		width: 300,
		height: 300,
		resizeMode: 'cover', // ["center","contain","cover","none","repeat","stretch"].
		// marginTop: '20px',
		// marginBottom: '20px',
	},
	scrollviewcontainer: {
		backgroundColor: '#f8f8f8',
		height: 300 + 100 + 40,
		marginTop: 20,
		marginBottom: 20,
		marginLeft: 10,
		marginRight: 10,
		borderColor: 'grey',
		borderRadius: 7,
		borderWidth: 1,
		alignItems: 'center',
		justifyContent: 'center',
	},
	scrollview: {
		marginHorizontal: 20,
		width: '80%',
	},
	error: {
		color: 'red',
		fontSize: 10,
	}
})

export default withFirebaseHOC(Home)
